/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');
// import 'datatables.net';
// import 'datatables.net-bs5';
// import 'datatables.net-buttons-bs5';
// require( 'datatables.net-buttons/js/buttons.colVis.js' )();
// require( 'datatables.net-buttons/js/buttons.html5.js' )();
// require( 'datatables.net-buttons/js/buttons.print.js' )();
// import 'datatables.net-buttons';
// import 'datatables.net-buttons-dt';
import "bootstrap-checkbox";
// import "bootstrap-datepicker";
import '@dashboardcode/bsmultiselect/dist/js/BsMultiSelect';

import './components/sidebar';
import './components/dynamicHeight';
import "./components/plugins";
import "./components/submit";
import "./components/counter";
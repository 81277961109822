$(function() {
  $(".counter").prepend('<div class="decrease button">-</div>');
  $(".counter").append('<div class="increase button">+</div>');
  $(".button").on("click", function() {

      var $button = $(this);
      var oldValue = $button.parent().parent().find("input[name='itemQty[]']").val();

      if ($button.text() == "+") {
        var newVal = parseFloat(oldValue) + 1;
      } else {
       // Don't allow decrementing below zero
        if (oldValue > 0) {
          var newVal = parseFloat(oldValue) - 1;
        } else {
          newVal = 0;
        }
      }

      $button.parent().parent().find("input[name='itemQty[]']").val(newVal);

  });
});
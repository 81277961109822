$("form").submit( function() {
  var _this = $(this);
  if(_this.is(`#${_this.attr('id')}`) && $(`button[form='${_this.attr('id')}']`)) {
    let button = $(`button[form='${_this.attr('id')}']`);
    button.html('Loading <i class="fas fa-spinner fa-spin"></i>')
    button.prop('disabled', true)
  } else {
    let button = $(`button[type="submit"]`);
    button.html('Loading <i class="fas fa-spinner fa-spin"></i>')
    button.prop('disabled', true)
  }
  
})
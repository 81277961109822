// const element = '';
// function dynamicHeight(element) {
//   const contentHeight = document.querySelector(element)
//   console.log(contentHeight)
// }
// dynamicHeight('menu-wrapper')
const dynamicHeight = document.querySelectorAll('.scrollable');

dynamicHeight.forEach(element => {
  element.style.height = `calc(100vh - ${element.getBoundingClientRect().top+'px'})`;
});
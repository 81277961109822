// let sidebarMenu = document.querySelector('.menu-button');
// let sidebarToggle = document.querySelector('.sidebar');
// let togglestatus = false;
// document.addEventListener('click', function(event) {
//   const isClickInside = sidebarMenu.contains(event.target);
//   if (isClickInside) {
//     sidebarToggle.classList.add('is-active');
//     togglestatus = true
//   } else {
//     sidebarToggle.classList.remove("is-active")
//   }
// })
$(".menu-button").on("click", function(event) { 
  event.stopPropagation();
  $(".sidebar").addClass('is-active')     
});
$(document).on("click", function (event) {
  var sidebar = $(".sidebar");
  if (!sidebar.is(event.target) && !sidebar.has(event.target).length) {
    sidebar.removeClass('is-active')
  }
});